.footer {
    /* background-image: url('../Image/homeBack.jpg'); 
    background-size: cover;
    background-position: center; */
    background-color: black;
    color: white;
    padding: 40px 20px;
    text-align: center;
  }

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.footer-section {
    flex: 1;
    min-width: 250px;
    margin: 10px;
}

.footer-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.footer-section p,
.footer-section ul {
    font-size: 1rem;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #ffde59;
}

.footer-bottom {
    text-align: center;
    padding: 10px;
    background-color: #012345;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
    }
}
