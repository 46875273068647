.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #023075;
    height: 80px;
    position: relative;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;
}

.logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.logo img {
    max-height: 90px;
    object-fit: contain;
}

.nav {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-right: 20px;
}

/* Navigation Links */
.nav a {
    color: #023075;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3rem;
    transition: color 0.3s ease;
    position: relative;
}

.nav a:hover {
    color: #1d5bbf;
    border-bottom: 2px solid #1d5bbf;
}

/* Hamburger Styles */
.hamburger {
    display: none; /* Hide by default */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.hamburger .line {
    background-color: #000;
    height: 3px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

/* Transform hamburger into a cross when open */
.hamburger.open .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 10.5px);
}

.hamburger.open .line:nth-child(2) {
    opacity: 0;
}

.hamburger.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -10.5px);
}

/* Dropdown Styles */
.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    color: #023075;
    min-width: 180px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 100%;
    left: 0;
    border-radius: 8px;
    overflow: hidden;
}

.dropdown:hover .dropdown-content {
    display: block;
    left: 0;
}

.dropdown-content a {
    color: #023075;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
    background-color: #f0f0f0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    /* Hide regular nav on small screens */
    .nav {
        display: none;
        flex-direction: column;
        gap: 10px;
    }

    /* Show hamburger on small screens */
    .hamburger {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* Open nav styles for small screens */
    .nav.open {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 20px;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        border-radius: 0 0 8px 8px;
    }

    /* Navigation Links when open */
    .nav.open a {
        color: #023075;
        padding: 15px;
        text-align: center;
        width: 100%;
    }

    .nav.open a:last-child {
        border-bottom: none;
    }

    .nav.open a:hover {
        color: #1d5bbf;
        background-color: #f9f9f9;
    }
}

  



