

/* Hero Section */
.hero {
    position: relative;
    background-image: url('../Image/homeBack.jpg'); 
    background-size: cover;
    background-position: center;
    padding: 60px 10px; /* Reduced padding for smaller screens */
    color: white;
    text-align: center;
}

.hero-content {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 30px; /* Reduced padding */
    border-radius: 10px;
    display: inline-block;
    max-width: 100%; /* Ensure it scales down on small screens */
    width: 90%; /* Allow some flexibility */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.hero h1 {
    font-size: 2rem; /* Adjust for smaller screens */
    margin-bottom: 15px;
}

.hero h2 {
    font-size: 1.5rem; /* Adjust for smaller screens */
    margin-bottom: 12px;
}

.hero p {
    font-size: 1rem; /* Adjust for smaller screens */
    margin-bottom: 8px;
}
.whatsapp-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #25D366; /* WhatsApp green color */
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
    background-color: #1ebe57; /* Darker green on hover */
    transform: scale(1.05); /* Slightly increase the size */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.whatsapp-button span {
    margin-right: 10px; /* Space between icon and text */
    font-size: 1.5rem; /* Adjust icon size */
}


/* Vendor Section */
.vendors {
    padding: 0px 20px;
    text-align: center;
    background-color: #f5f5f5;
}

.vendor-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Stack grid and dots vertically */
    position: relative;
    width: 100%;
    max-width: 1500px; /* Limit the max width for large screens */
    overflow: visible; /* Ensure dots are visible */
}

.vendor-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 40vh; /* Adjust height relative to viewport for responsiveness */
    transition: transform 0.5s ease;
    overflow: visible; /* Ensure items don't overflow */
}

.vendor-item {
    width: 15vw; /* Responsive width based on viewport */
    height: 15vw; /* Responsive height based on viewport */
    max-width: 200px; /* Set a max width */
    max-height: 200px; /* Set a max height */
    background-size: cover;
    background-position: center;
    transition: all 0.5s ease;
    border-radius: 10px;
    margin: 0 20px;
    filter: blur(4px);
    opacity: 0.5;
    position: absolute;
}

.vendor-item.active {
    width: 30vw; /* Responsive active item size */
    height: 15vw; /* Responsive active item size */
    max-width: 400px; /* Limit active item size */
    max-height: 400px; /* Limit active item size */
    filter: none;
    opacity: 1;
    transform: translateX(0);
    z-index: 2;
    /* margin-top: 50px;
    margin-bottom: 50px; */
}

.vendor-item.left {
    transform: translateX(-25vw); /* Adjust positioning for smaller screens */
    opacity: 0.7;
    filter: blur(2px);
    z-index: 1;
}

.vendor-item.right {
    transform: translateX(25vw); /* Adjust positioning for smaller screens */
    opacity: 0.7;
    filter: blur(2px);
    z-index: 1;
}

/* Scroll buttons */
.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 3rem;
    cursor: pointer;
    color: black;
    z-index: 3;
    
}

.scroll-button.left {
    left: 0;
    padding-left: 120px;
    position: absolute;
    top: 50%; /* Vertically center */
    transform: translateY(-50%);
}

.scroll-button.right {
    right: 0;
    padding-right: 120px;
    position: absolute;
    top: 50%; /* Vertically center */
    transform: translateY(-50%);
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
    .scroll-button.left {
        padding-left: 20px; /* Reduce padding for smaller screens */
    }

    .scroll-button.right {
        padding-right: 20px; /* Reduce padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .scroll-button.left {
        padding-left: 10px; /* Further reduce for very small screens */
    }

    .scroll-button.right {
        padding-right: 10px; /* Further reduce for very small screens */
    }
}

/* Dots Navigation */
.dots-navigation {
    margin-top: 100px; /* Ensure enough space between grid and dots */
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between the dots */
}

.dot {
    width: 12px;
    height: 12px;
    background-color: #aaa;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: #023075;
}

/* Media Queries for Responsiveness */

/* For screens smaller than 768px (tablets and smaller) */
@media (max-width: 768px) {
    .vendor-item {
        width: 25vw; /* Adjust smaller item size */
        height: 25vw; /* Adjust smaller item size */
        max-width: 150px; /* Reduce max width on smaller screens */
        max-height: 150px; /* Reduce max height on smaller screens */
    }

    .vendor-item.active {
        width: 50vw; /* Larger active item on smaller screens */
        height: 50vw;
        max-width: 300px; /* Adjust max width for active item */
        max-height: 300px; /* Adjust max height for active item */
    }

    .vendor-item.left, .vendor-item.right {
        transform: translateX(30vw); /* Adjust side items positioning */
    }
}

/* For screens smaller than 480px (mobile phones) */
@media (max-width: 480px) {
    .vendor-item {
        width: 30vw; /* Further adjustment for mobile screens */
        height: 30vw;
        max-width: 120px; /* Reduce max width on mobile */
        max-height: 120px; /* Reduce max height on mobile */
    }

    .vendor-item.active {
        width: 60vw;
        height: 60vw;
        max-width: 240px; /* Adjust max width for mobile */
        max-height: 240px; /* Adjust max height for mobile */
    }

    .vendor-item.left, .vendor-item.right {
        transform: translateX(25vw); /* Narrower spacing for mobile */
    }

    .scroll-button {
        font-size: 1.5rem; /* Smaller scroll buttons on mobile */
    }
}

/* For large screens (e.g., 1200px and above) */
@media (min-width: 1200px) {
    .vendor-item {
        max-width: 180px; /* Limit the size of the grid items */
        max-height: 180px; /* Limit the size of the grid items */
    }

    .vendor-item.active {
        max-width: 350px; /* Limit the size of the active item */
        max-height: 350px; /* Limit the size of the active item */
    }
}





/* Facts Section */
.facts {
    padding: 50px 20px;
    text-align: center;
    color: black;
    position: relative;
    overflow: hidden;
    background-color: #f5f5f5;
}

/* Fact Container Styling */
.facts-container {
    display: flex;
    justify-content: space-between; /* Space out items */
    align-items: stretch; /* Ensure all items have the same height */
    gap: 40px;
    flex-wrap: wrap;
    padding-top: 40px;
}

/* Fact Item Styling */
.fact-item {
    background-color: #ffffff; /* Light background for contrast */
    text-align: center;
    padding: 20px;
    border: 2px solid #ddd; /* Border for rectangular shape */
    border-radius: 8px; /* Slightly rounded corners */
    flex: 1 1 calc(33.333% - 40px); /* Three columns with space */
    max-width: 400px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for the fact items */
.fact-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Subtle hover shadow */
}

/* Counter Text Styling */
.fact-item h3 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333; /* Darker text color */
}

/* Fact Description */
.fact-item p {
    font-size: 1.1rem;
    color: #666; /* Muted description color */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .fact-item {
        flex: 1 1 calc(50% - 40px); /* Two columns on medium screens */
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .facts-container {
        flex-direction: column;
        align-items: center; /* Center items on smaller screens */
        gap: 20px;
    }

    .fact-item {
        flex: 1 1 100%; /* Full width on smaller screens */
        max-width: 100%; /* Prevent overflow */
        width: 100%; /* Ensure box takes up full width */
    }
}

@media (max-width: 480px) {
    .fact-item h3 {
        font-size: 2rem; /* Smaller font size on small screens */
    }

    .fact-item p {
        font-size: 1rem;
    }
}











/* Contact Us Section */
.contact-us {
    padding: 50px 20px;
    background-image: url('../Image/contactuss.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    text-align: center;
}

.contact-heading {
    flex: 1;
    text-align: center; /* Center text alignment */
    margin-bottom: 20px;
}

.contact-heading h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: white;
}

.contact-heading h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: white;
    display: flex;
    justify-content: center; /* Center the WhatsApp number */
    align-items: center;
}

.contact-heading h2::before {
    content: "📞 ";
    font-size: 1.5rem;
    margin-right: 10px;
}

.google-form {
    flex: 1;
    width: 100%; /* Ensures it adapts to the parent's width */
    max-width: 600px;
    min-height: 500px; /* Sets a minimum height for the form */
    margin-top: 20px; /* Separates the form from the heading */
    padding: 20px; /* Adds padding inside the form container */
    border-radius: 10px; /* Rounds the corners of the form container */
    background-color: #ffffff; /* White background for the form */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow on hover */
    border: 1px solid #e0e0e0; /* Light border to distinguish the form */
}

.google-form:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}

.google-form iframe {
    width: 100%; /* Ensure the embedded Google Form fits within the container */
    height: 500px; /* Adjusts height to fit the container */
    border: none; /* Removes default iframe border */
    border-radius: 8px; /* Rounds the corners of the embedded form */
}

.google-form::-webkit-scrollbar {
    width: 6px; /* Custom scrollbar width */
}

.google-form::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Scrollbar color */
    border-radius: 3px; /* Round scrollbar edges */
}

.google-form::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Darker color on hover */
}


/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    .contact-us {
        flex-direction: row-reverse; /* Display form on the left and text on the right */
        align-items: flex-start; /* Align items to the top */
    }

    .contact-heading {
        text-align: right; /* Align text to the right */
        margin-bottom: 0;
    }

    .contact-heading h1 {
        font-size: 3rem;
    }

    .contact-heading h2 {
        font-size: 1.5rem;
        justify-content: flex-end; /* Align the WhatsApp number to the right */
    }

    .google-form {
        margin-right: 20px; /* Space between the form and heading */
    }

    .facts-grid {
        flex-direction: row; /* Align items in a row on medium screens */
        justify-content: space-around;
    }
}

@media (min-width: 1024px) {
    .hero-content {
        padding: 50px;
    }

    .hero h1 {
        font-size: 3rem;
    }

    .hero h2 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1.2rem;
    }

    .vendor-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Increase column size */
    }

    .fact-item {
        width: 30%; /* Adjust width on larger screens */
    }

    .contact-heading h1 {
        font-size: 5rem;
    }

    .contact-heading h2 {
        font-size: 1.8rem;
        justify-content: flex-end; /* Ensure it remains aligned to the right */
    }
}






.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .whatsapp-float img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  


  /* Fade-in and slide-up animation */
.fade-in-up {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-up.visible {
    opacity: 1;
    transform: translateY(0);
}


/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
    .hero {
        padding: 40px 15px; /* Further reduce padding */
    }
    
    .hero h1 {
        font-size: 1.8rem; /* Smaller font size */
    }
    
    .hero h2 {
        font-size: 1.3rem; /* Smaller font size */
    }
    
    .hero p {
        font-size: 0.95rem; /* Adjust for readability on small screens */
    }
    
    .whatsapp-button {
        font-size: 1rem; /* Adjust button size */
        padding: 8px 16px;
    }
}





/* Announcement Section Styles */
.announcement {
    width: 100%;
    background-color: #ffcc00;
    color: #000;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    overflow: hidden; /* Ensures text is clipped when it moves out of bounds */
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .announcement p {
    margin: 0;
    display: inline-block;
    padding-left: 100%; /* Start the text outside the visible area */
    animation: scroll-text 20s linear infinite; /* Continuous scrolling effect */
  }
  
  /* Keyframe animation for scrolling the text */
  @keyframes scroll-text {
    0% {
      transform: translateX(100%); /* Start position (off the right side) */
    }
    100% {
      transform: translateX(-100%); /* End position (off the left side) */
    }
  }
  








  /* Reviews Section */
.reviews {
    /* padding: 50px 0; */
    padding-bottom: 50px;
    text-align: center;
  }
  
  .reviews-grid {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .review-item {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    transition: transform 0.3s ease;
  }
  
  .review-item:hover {
    transform: translateY(-10px);
  }
  
  .review-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .review-text {
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .review-name {
    font-weight: bold;
    color: #333;
  }
  