.vendor-page-container {
    padding: 40px 20px;
    text-align: center;
    background-color: #f7f9fc; /* Light background to enhance contrast */
  }
  
  

 
  .vendor-page-title {
    text-align: center;
    font-size: 30px;
    color: black;
    
    text-transform: uppercase;
    font-weight: bolder;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  
  .vendor-page-title::after {
    content: "";
    display: block;
    width: 60%; /* Adjust the width of the underline */
    height: 4px; /* Thickness of the underline */
    background-color: black; /* Color of the underline */
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); /* Center the underline */
    transition: width 0.3s ease;
  }
  
  .vendor-page-title:hover::after {
    width: 80%; /* Expands underline on hover */
  }

  
  
  



  
  .vendor-page-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px; /* Larger gap between items for better spacing */
    padding: 0 40px;
  }
  
  .vendor-page-link {
    text-decoration: none;
  }
  
  .vendor-page-item {
    background-size: cover;
    background-position: center;
    height: 250px; /* Increased height for a bolder look */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transform & shadow effects */
    border-radius: 10px; /* Softened corners */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  }
  
  .vendor-page-item:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
  }
  
  .vendor-page-name {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 15px;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase; /* Uniform look for all vendor names */
    letter-spacing: 1px;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .vendor-page-item:hover .vendor-page-name {
    background-color: rgba(0, 0, 0, 0.8); /* Darker overlay on hover */
  }
  
  /* Media Queries for Responsive Design */
  
  /* For tablet devices (1024px and down) */
  @media (max-width: 1024px) {
    .vendor-page-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet */
      padding: 0 20px;
      gap: 25px;
    }
  }
  
  /* For mobile devices (768px and down) */
  @media (max-width: 768px) {
    .vendor-page-grid {
      grid-template-columns: repeat(1, 1fr); /* 1 column on mobile */
      padding: 0 15px;
      gap: 20px;
    }
  
    .vendor-page-title {
      font-size: 2rem; /* Smaller title for mobile */
    }
  
    .vendor-page-item {
      height: 220px;
    }
  }
  
  /* For small mobile devices (576px and down) */
  @media (max-width: 576px) {
    .vendor-page-title {
      font-size: 1.8rem;
    }
  
    .vendor-page-item {
      height: 200px;
    }
  
    .vendor-page-name {
      font-size: 1rem;
    }
  }
  