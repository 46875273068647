/* About Us Section */
.about-us {
    font-family: 'Arial', sans-serif;
    color: white;
    line-height: 1.6;
  }
  
  .about-us-header {
    position: relative;
    background-image: url('../Image/homeBack.jpg'); 
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 50px 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-header h1 {
    font-size: 2.9rem;
    margin-bottom: 10px;
    color: white;
  }
  
  .about-us-header p {
    font-size: 1.2rem;
    color: wheat;
  }
  
  .about-us-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    gap: 50px;
  }
  
  .about-us-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-text {
    max-width: 600px;
  }
  
  .about-us-text h2 {
    font-size: 4rem;
    margin-bottom: 10px;
    color: #023075;
  }
  
  .about-us-text p {
    font-size: 1.3rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .our-values {
    text-align: center;
    padding: 50px 20px;
    background-color: #f8f9fa;
  }
  
  /* .our-values h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #023075;
  } */
  
  .values-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .value-item {
    background-color: white;
    padding: 20px;
    border-radius: 100px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .value-item h3 {
    font-size: 1.5rem;
    color: #023075;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .value-item p {
    font-size: 1rem;
    color: #555;
    
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-us-header h1 {
      font-size: 2.4rem;
    }
  
    .about-us-header p {
      font-size: 1rem;
    }
  
    .about-us-content {
      flex-direction: column;
      text-align: center;
      padding: 30px 10px;
      gap: 30px;
    }
  
    .about-us-text h2 {
      font-size: 1.8rem;
    }
  
    .about-us-text p {
      font-size: 0.9rem;
    }
  
    .our-values h2 {
      font-size: 2rem;
    }
  
    .value-item h3 {
      font-size: 1.3rem;
    }
  
    .value-item p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-header h1 {
      font-size: 2rem;
    }
  
    .about-us-header p {
      font-size: 0.9rem;
    }
  
    .about-us-content {
      padding: 20px 10px;
    }
  
    .about-us-text h2 {
      font-size: 1.5rem;
    }
  
    .about-us-text p {
      font-size: 0.8rem;
    }
  
    .our-values h2 {
      font-size: 1.8rem;
    }
  
    .value-item h3 {
      font-size: 1.2rem;
    }
  
    .value-item p {
      font-size: 0.8rem;
    }
  }
  
