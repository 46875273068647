/* src/Styling/Vendor.css */


.vendor-page {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
   
}

.vendor-name {
    text-align: center;
    margin-bottom: 40px;
    background-image: url('../Image/homeBack.jpg'); /* Background image for vendor name section */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.vendor-name h1 {
    font-size: 2.5rem;
    margin: 0;
}

.certifications {
    text-align: center;
    background-image: url('../Image/homeBack.jpg'); 
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 10px;
}

.certifications h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.certification-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Fixed 3x3 grid */
    gap: 20px;
    max-width: 100%; /* Ensure grid fits within container */
    margin: 0 auto; /* Center the grid */
}

.certification-item {
    text-align: center;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background for certificate items */
    border-radius: 10px;
    padding: 10px;
}

.certification-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.certification-item p {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bolder;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
    .vendor-name h1 {
        font-size: 2rem; /* Smaller font size on small screens */
    }

    .certifications h2 {
        font-size: 1.5rem; /* Smaller font size for subheading */
    }

    .certification-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
    }

    .certification-item p {
        font-size: 1rem; /* Adjust font size */
    }
}

@media (max-width: 480px) {
    .vendor-name h1 {
        font-size: 1.5rem; /* Even smaller font size on very small screens */
    }

    .certifications h2 {
        font-size: 1.2rem; /* Adjust font size for subheading */
    }

    .certification-grid {
        grid-template-columns: 1fr; /* Single column on very small screens */
    }

    .certification-item p {
        font-size: 0.9rem; /* Adjust font size */
    }
}
